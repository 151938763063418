<template>
  <v-container fluid class="pb-0" :class="formClass">
    <v-form @submit.prevent="submitForm" ref="form" id="base-form" v-model="valid" lazy-validation>
      <v-row justify="space-between">
        <v-col v-if="$slots.default" cols="12" md="4" align-self="center" class="pb-0">
          <slot></slot>
        </v-col>
        <v-col v-for="n in numberOfCols" :key="n" cols="12" md="4" class="d-flex flex-column">
          <div v-for="(input, key, index) in inputs" :key="index" :id="index">
            <div v-if="n == input.colNum" id="form" class="pb-1">
              <v-text-field
                v-if="input.isText"
                v-model="reactiveVar[key]"
                outlined
                dense
                clearable
                :rules="input.rules"
                :label="input.label"
                :required="input.required"
                :disabled="input.disabled"
                :placeholder="input.label"
                :prepend-icon="input.icon"
              ></v-text-field>
              <v-textarea
                v-if="input.isTextArea"
                v-model="reactiveVar[key]"
                outlined
                dense
                clearable
                :rules="input.rules"
                :label="input.label"
                :required="input.required"
                :disabled="input.disabled"
                :placeholder="input.label"
                :prepend-icon="input.icon"
              ></v-textarea>
              <v-select
                v-if="input.isSelectBox"
                ref="select"
                v-model="reactiveVar[key]"
                outlined
                clearable
                dense
                :menu-props="{ offsetY: true }"
                :items="input.items"
                :rules="input.rules"
                :label="input.label"
                :prepend-icon="input.icon"
                :required="input.required"
                @change="setProgress"
              >
                <!-- なぜデフォルト設定を変更するのか？（id, valueというデータの組みを受け取るという話ではなく設計として） -->
                <!-- https://vuetifyjs.com/ja/components/selects/#section-30ab30b930bf30e0text3068value -->
                <!-- <template v-slot:selection="{ item }">
                  <span>{{ item.value }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <span>{{ item.value }}</span>
                </template> -->
              </v-select>
              <v-checkbox
                v-if="input.isCheckbox"
                v-model="reactiveVar[key]"
                dense
                hide-details
                class="mb-2"
                :items="input.items"
                :rules="input.rules"
                :required="input.required"
                :prepend-icon="input.icon"
                :label="input.label"
              >
              </v-checkbox>
              <Calendar
                v-if="input.isCalendar"
                :ref="'calendar-' + n"
                :input="input"
                :reactiveVarKey="key"
                :index="index"
                :initDate="reactiveVar[key]"
                @date-range="updateDate"
              />
            </div>
          </div>
          <v-card-actions v-if="n == numberOfCols" class="justify-end mb-sm-4 mb-xs-1 px-0 mt-auto">
            <v-btn v-if="searchBtn" :disabled="!valid" outlined color="primary" type="submit" form="base-form">
              {{ labels.BTN_SEARCH }}
            </v-btn>
            <v-btn
              v-if="registerBtn"
              :disabled="!valid || uploading"
              :loading="uploading"
              outlined
              color="primary"
              type="submit"
              form="base-form"
              @click.stop="showModal"
            >
              {{ labels.REGISTER }}
            </v-btn>
            <v-btn v-if="resetBtn" outlined @click="reset" color="warning">
              {{ labels.BTN_RESET }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import Calendar from './Calendar.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  components: { Calendar },
  props: {
    align: {
      type: String,
      required: false,
      default: 'start',
    },
    formClass: {
      type: String,
      required: false,
    },
    inputs: {
      type: Object,
      required: true,
    },
    numberOfCols: {
      type: Number,
      required: false,
      default: 3,
    },
    reactiveVar: {
      type: Object,
      required: true,
    },
    searchBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    registerBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  // emits: ["submit-form"],
  data() {
    return {
      valid: true,
      calendarKeys: [],
      progress: null,
    };
  },
  computed: {
    ...mapGetters({
      labels: 'defined/labels',
      messages: 'defined/messages',
      uploading: 'ui/uploadLoading',
      mode: 'ui/mode',
    }),
  },
  methods: {
    ...mapActions({
      toggleShowModal: 'ui/toggleShowModal',
      setModalTitle: 'ui/setModalTitle',
      setModalMessage: 'ui/setModalMessage',
      setModalConfirmBtnLabel: 'ui/setModalConfirmBtnLabel',
      setMode: 'ui/setMode',
    }),

    setProgress(changedItemValue) {
      const key = this.$route.meta.storeModule;
      const progressStatus = this.$store.state.defined.progressStatus[key];
      const progress = progressStatus ? progressStatus.find(({ text }) => text === changedItemValue) : null;
      this.progress = progress;
    },

    submitForm() {
      // if (this.$refs.select && this.$refs.select[0].selectedItems[0]) {
      //   this.reactiveVar.status = JSON.parse(
      //     JSON.stringify(this.$refs.select[0].selectedItems[0])
      //   );
      // }
      // this.reactiveVar.progress = this.progress;
      this.$refs.form.validate()
        ? this.$emit('submit-form', this.reactiveVar)
        : this.$emit('submit-form', this.$refs.form.validate());
    },
    updateDate(dateRange, key) {
      this.reactiveVar[key] = dateRange;
      this.calendarKeys.push(key);
    },
    reset() {
      this.$refs.form.reset();
      this.reactiveVar = {};
      this.submitForm();
      this.setMode(this.labels.REGISTER);
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    showModal() {
      this.toggleShowModal();
      if (this.mode === this.labels.REGISTER) {
        this.setModalTitle(this.labels.REGISTER_CONFIRM);
        this.setModalMessage(this.messages.REGISTER_CONFIRM);
        this.setModalConfirmBtnLabel(this.labels.REGISTER);
      } else if (this.mode === this.labels.UPDATE) {
        this.setModalTitle(this.labels.UPDATE_CONFIRM);
        this.setModalMessage(this.messages.UPDATE_CONFIRM);
        this.setModalConfirmBtnLabel(this.labels.UPDATE);
      }
    },
  },
};
</script>
